.tableAreaSec {
	.ant-page-header-content {
		background-color: #fff;
		padding: 12px;
		margin-top: 18px;
		border-radius: 10px;
		box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.14);

		.listCountNew {
			text-align: right;
			margin-bottom: 12px;

			.ant-btn {
				padding: 10px 14px;
				height: auto;
				line-height: 16px;
				border-radius: 8px;
				font-size: 14px;
				border-color: $input_border;
				margin-left: 15px;

				&:hover {
					background-color: $primary_color;
					color: $white_color;
				}
			}

			.ant-select {
				margin-left: 15px;
			}

			.ant-select-selector {
				height: auto;
				padding: 3px 12px;
				border-radius: 8px !important;
				text-align: left;
				font-size: 14px;
				background-color: $white_color;

				.ant-select-selection-placeholder {
					line-height: 30px;
				}
			}

			.ant-select-selection-item {
				line-height: 30px;
			}
		}
	}
}

.ant-modal {
	padding-bottom: 0;

	.ant-modal-header {
		text-align: center;
		border-radius: 0;
		border-bottom: 1px solid $black_300;
		padding: 16px;

		.ant-modal-title {
			font-size: 22px;
			padding-right: 30px;
		}
	}

	.ant-modal-content {
		border-radius: 20px;
		overflow: hidden;
		max-height: 90vh;

		.ant-modal-body {
			padding: 50px 30px 25px;
			max-height: calc(90vh - 182px);
			overflow-y: auto;

			.ant-form-item {
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}
			}
			.item-margin-botton-remove{
				.ant-form-item {
					margin-bottom: 0;
				}
			}
		}
	}

	.ant-modal-footer {
		border-top: 1px solid $black_300;
		padding: 20px;
		text-align: center;

		button {
			padding: 10px 20px;
			height: auto;
			border-radius: 10px;
			font-size: 16px;
			line-height: normal;
			min-width: 100px;
			margin-left: 0;
		}

		.cancelBtn {
			border-color: $primary_color;
			color: $primary_color;
			@include themeColorOpacity($primary, 0.1);
		}
	}

	.ant-input,
	.ant-select-selector,
	.ant-select:not(.ant-select-customize-input) .ant-select-selector,
	.ant-input-number {
		background-color: $ag_alter_color;
	}

	.ant-modal-close-x {
		height: 66px;
		line-height: 66px;
		font-size: 24px;
		color: $icon_gray_color;

		svg {
			transition: all 0.3s ease;

			&:hover {
				transform: rotate(90deg);
				color: $primary_color;
			}
		}
	}

	.ant-select-selector .ant-select-selection-search {
		left: 20px;
	}

	.uploadFile_sec {
		display: flex;
		align-items: center;
	}

	.uploadFile_sec svg {
		width: 40%;
	}

	.uploadFile_sec p {
		width: 60%;
		padding: 0 8px;
	}

	.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
		border-color: $primary_color;
	}

	@include desktop {
		.ant-modal-content {
			.ant-modal-body {
				padding: 40px 30px 15px;
			}
		}
	}
}

.addModal,
.editModal {
	.ant-modal-header {
		border-top: 6px solid $primary_color;
	}

	.ant-form>.ant-row {
		&>.ant-col {
			margin-bottom: 25px;
			// &:last-child {
			// 	margin-bottom: 0;
			// }
		}
	}
}

.withoutFormFields {
	&>.ant-col {
		margin-bottom: 25px;
	}

	.ant-col {
		label {
			padding: 0 0 8px 0;
		}
	}
}

.deleteModal {
	.ant-modal-header {
		border-top: 6px solid $danger_color;
	}

	.ant-form>.ant-row {
		&>.ant-col {
			margin-bottom: 25px;
		}
	}

	.ant-modal-body {
		h3 {
			color: $input_label_color;

			span {
				color: $black_color;
			}
		}
	}

	.ant-modal-footer {
		.cancelBtn {
			border-color: $danger_color;
			color: $danger_color;
			@include themeColorOpacity($danger_color, 0.1);
		}

		.ant-form-item {
			margin-bottom: 0;
		}
	}

	.ant-modal-close-x {
		svg {
			&:hover {
				color: $danger_color;
			}
		}
	}
}

.tabModal .ant-modal-content .ant-modal-body {
	padding: 0;

	.ant-tabs-content-holder {
		padding: 20px 0;
	}
}

.ant-btn.editIcon,
.editIcon {
	color: $edit_icon_color;
	border: 1px solid $edit_icon_color;
	border-radius: 5px;
	font-size: 15px;
	// background-color: rgba($edit_icon_color, 0.1);
	padding: 0 !important;
	width: 28px;
	height: 28px;
	@include themeColorOpacity($edit_icon_color, 0.1);
}

.ant-btn.deleteIcon,
.deleteIcon {
	color: $danger_color;
	border: 1px solid $danger_color;
	border-radius: 5px;
	font-size: 15px;
	// background-color: rgba($danger_color, 0.1);
	padding: 0 !important;
	width: 28px;
	height: 28px;
	@include themeColorOpacity($danger_color, 0.1);
}

.deleteIcon.ant-tooltip-disabled-compatible-wrapper {
	line-height: 15px;
	padding: 3.5px 5px !important;

	button.ant-btn {
		padding: 0 !important;
		line-height: 15px;
		font-size: inherit;
	}
}

.ant-btn.scopeIcon,
.scopeIcon {
	color: #2d38df;
	border: 1px solid #2d38df;
	border-radius: 5px;
	font-size: 15px;
	padding: 0 !important;
	width: 28px;
	height: 28px;
	@include themeColorOpacity(#2d38df, 0.1);
}

.ant-btn.viewIcon,
.viewIcon {
	color: #2d38df;
	border: 1px solid #2d38df;
	border-radius: 5px;
	font-size: 15px;
	padding: 0 !important;
	width: 28px;
	height: 28px;
	@include themeColorOpacity(#2d38df, 0.1);
}

.ant-btn.widgetIcon,
.widgetIcon {
	color: #52c41a;
	border: 1px solid #52c41a;
	border-radius: 5px;
	font-size: 15px;
	padding: 0 !important;
	width: 28px;
	height: 28px;
	@include themeColorOpacity(#52c41a, 0.1);
}

.widgetIcon img {
	width: 20px;
}

.ant-btn.rollbackIcon,
.rollbackIcon {
	color: #57ea0f;
	border: 1px solid #57ea0f;
	border-radius: 5px;
	font-size: 14px;
	padding: 0 !important;
	width: 28px;
	height: 28px;
	@include themeColorOpacity(#57ea0f, 0.1);
}

.ant-btn.transferIcon,
.transferIcon {
	color: #d72ddf;
	border: 1px solid #d72ddf;
	border-radius: 5px;
	font-size: 14px;
	padding: 0 !important;
	width: 28px;
	height: 28px;
	@include themeColorOpacity(#d72ddf, 0.1);
}

.ant-btn.resignIcon,
.resignIcon {
	color: #d72ddf;
	border: 1px solid #d72ddf;
	border-radius: 5px;
	font-size: 14px;
	padding: 0 !important;
	width: 28px;
	height: 28px;
	@include themeColorOpacity(#d72ddf, 0.1);
	svg, path{
		fill: #d72ddf;
	}
&.resignIcon-align{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
    top: 3px;
}
}

.ant-btn.recordIcon,
.recordIcon,
.ant-btn.reopenIcon,
.reopenIcon {
	color: #52c41a;
	border: 1px solid #52c41a;
	border-radius: 5px;
	font-size: 15px;
	padding: 0 !important;
	width: 28px;
	height: 28px;
	@include themeColorOpacity(#52c41a, 0.1);
}

.ant-btn.skyBlueIcon,
.skyBlueIcon {
	color: #1ac1c4;
	border: 1px solid #1ac1c4;
	border-radius: 5px;
	font-size: 15px;
	padding: 0 !important;
	width: 28px;
	height: 28px;
	@include themeColorOpacity(#1ac1c4, 0.1);
}

.grid_wrapper .cellHeight {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-breadcrumb a:hover {
	color: $primary_color;
}

.titleSeprator {
	line-height: 22px;
	margin: 0;
	padding-top: 15px;
	border-top: 1px solid $black_200;
	font-size: 19px;
}

.scope_radio {
	margin-bottom: 20px;
	display: flex;
	border: 1px solid $input_border;
	border-radius: 8px;
	overflow: hidden;

	label {
		padding: 14px 0;
		display: flex;
		align-items: center;
	}

	>label {
		padding-left: 20px;
		min-width: 250px;
		background-color: $black_200;
	}

	.scope_inner_radio {
		padding-left: 20px;

		.ant-checkbox-wrapper {
			display: inline-flex;
			margin: 0;
			margin-right: 10px;

			span {
				color: $input_label_color;
			}
		}
	}

	@include ipad {
		flex-wrap: wrap;

		label {
			padding: 8px 0;
		}

		>label {
			min-width: 100%;
			padding-left: 20px;
		}
	}
}

.tabModal .ant-tabs-nav {
	.ant-tabs-nav-wrap {
		overflow: visible;
	}

	.ant-tabs-tab {
		padding: 15px 30px;

		.ant-tabs-tab-btn {
			color: $input_label_color;
		}

		&.ant-tabs-tab-active {
			@include themeColorOpacity($primary, 0.1);
			position: relative;

			.ant-tabs-tab-btn {
				color: $primary_color;
			}

			&:after {
				content: "";
				display: inline-block;
				width: 0;
				height: 0;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-left: 5px solid $primary_color;
				position: absolute;
				right: -5px;
			}
		}
	}

	.ant-tabs-ink-bar {
		background: $primary_color;
	}
}

.page_listing {
	h3 {
		margin-bottom: 30px;
	}

	a {
		width: 100%;
		display: inline-block;
		padding: 10px 10px;
		margin-bottom: 20px;
		background-color: $white_color;
		font-size: 14px;
		color: $input_label_color;
		text-align: center;
		-webkit-box-shadow: 0px 0px 12px -7px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 12px -7px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 0px 12px -7px rgba(0, 0, 0, 0.75);
		border-radius: 8px;
	}
}

.insurance_collapse .collapse_row>.ant-col {
	margin-bottom: 25px;
}

.modelBorderText {
	display: flex;
	align-items: center;
	padding: 0 20px;
	height: 42px;
	border-radius: 8px;
	border: 1px solid #d0d0d0;
	font-size: 16px;
	color: #141414;
	outline: 0;
	background-color: #f8f8f8;
}

.delivery_add_checkbox {
	.ant-checkbox-wrapper {
		align-items: center;

		.ant-checkbox {
			top: 0;
		}
	}
}

.ant-table-cell-fix-right {
	button.ant-btn.ant-btn-text.ant-btn-lg {
		vertical-align: middle;
	}
}
.col-title-style{
	font-size: 18px;
	font-weight: 800;
	margin: 0;
}
.defaultdatefliter{
	max-width:450px;
	display: inline-flex;
	align-items: center;
	.ant-form-item{
		margin-bottom: 0;
	}
	.ant-picker{
		height: 38px;
	}
}
.chassis_free_check{
	.ant-form-item-label{
		margin-bottom: 15px;
	}
	.ant-form-item-control-input-content{
		.ant-checkbox-wrapper{
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			span{
				top: 0;
				.ant-checkbox-inner{
					width: 26px;
					height: 26px;
					&::after{
						left: 24%;
						top: 45%;
					}
				}
				
			&~span{
				line-height: 14px;
			}
			}
			.ant-checkbox-indeterminate{
				.ant-checkbox-inner::after {
					top: 50%;
					left: 50%;
					background-color: $primary_color;
				}
			}
		}
	}
}
.RemoveFreeChassis{
	.ant-modal-content{
		.ant-modal-body{
			padding: 20px 30px 25px;
		}
	}
}
.tooltip_with_lable{
	textarea {
		padding: 10px 12px !important;
	}
}

.green-row .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
    opacity: 0.5;
}
.green-row .ag-input-field-input.ag-checkbox-input {
    opacity: 0.5 !important;
    pointer-events: none;
}
.zformSearchBtnWithInput{
	display: inline-flex;
	align-items: center;
	.zformSearchBtn{
		margin-left: 10px;
	}
}