.reportWrap {
	box-shadow: 0px 10px 8px -8px rgba(0,0,0,0.2);
    padding: 0;
    border-radius: 8px;
    border-left: 3px solid $primary_color;
	.ant-card-head {
		min-height: 1px;
		padding: 0;
		border-bottom: 0;
		.ant-card-head-wrapper {
			display: inline-block;
			.ant-card-head-title {
				font-size: 16px;
				letter-spacing: 0.02em;
				padding: 6px 20px;
				background-color: $primary_color;
				display: inline-block;
				width: auto;
				min-width: 180px;
				color: $white_color;
				margin: 0px;
				border-radius: 4px 0 50px 0;
			}
		}
	}
	.ant-card-body {
		padding: 0px 25px 10px 25px;
		.reportInner {
			margin: 10px 20px 10px 0;
			padding: 0;
			border: 1px solid #ddd;
			border-radius: 4px;
			cursor: pointer;
			display: inline-flex;
			// width: 260px;
			overflow: hidden;
			&:hover {
				border: 1px solid $primary_color;
			}
			.reportIcon {
				width: 42px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #f3f3f3;
				font-size: 20px;
				color: $primary_color;
			}
			p {
				margin: 0;
    			padding: 10px 10px;
				font-size: 16px;
			}
		}
	}
}
.ant-page-header-content {
	.ag-theme-alpine {
		.loc_short_name_color_cg{
			color: $primary_color;
			cursor: pointer;
		}
	}
}
.ag-floating-bottom-container{
	.ag-row-pinned{
		.loc_short_name_color_cg[col-id="loc_short_name"]{
			pointer-events: none;
			color: #616161;
		}
	}
}