.dashboard_widget_block {
	background-color: $white_color;
	-webkit-box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.75);
	border-radius: 12px;
	overflow: hidden;
	margin-bottom: 20px;

	.widget_wrapper {
		transition: all .5s ease;

		.ant-empty {
			padding: 15px 0;
		}

		.top_img img {
			max-height: 100px;
			max-width: 150px;
			margin: 0 auto 10px;
			display: block;

			@include desktop {
				max-height: 100px;
				max-width: 55%;
			}
		}
	}

	.widget_title {
		padding: 10px 22px 10px 22px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		h3 {
			margin: 0;
			font-size: 17px;
			color: $black_color;
			line-height: normal;

			@include ipad {
				font-size: 14px;
			}
		}

		.refresh_toggle_icon {
			display: flex;
			align-items: center;

			svg {
				color: $black_color;
				cursor: pointer;
				width: 18px;
			}
		}
	}

	.widget_data {
		padding: 0 10px;
		list-style: none;
		margin: 0;

		.widget_row {
			display: flex;
			// justify-content: space-between;
			align-items: center;
			padding: 10px 12px;

			// &:nth-child(odd) {
			// 	border-left: 2px solid $icon_gray_color;
			// 	background-color: $black_200;
			// }
			.widget_name {
				font-size: 14px;

				p {
					margin: 0;
					font-size: 16px;
					color: $input_label_color;
					line-height: normal;

					span {
						display: block;
						font-size: 12px;
						color: $black_500;
						line-height: normal;
						margin-top: 5px;
					}
				}

				@include desktop {
					p {
						font-size: 14px;
					}
				}

				@include ipad {
					p {
						font-size: 12px;
					}
				}

			}

			.widget_date {
				border-right: 1px solid $black_200;
				padding-right: 12px;
				margin-right: 25px;
				min-width: 60px;

				p {
					margin: 0;
					font-size: 18px;
					color: $primary_color;
					text-align: center;
					line-height: normal;

					span {
						font-size: 24px;
						display: block;
						line-height: normal;
					}
				}

				@include desktop {
					p {
						font-size: 16px;

						span {
							font-size: 22px;
						}
					}
				}

				@include ipad {
					min-width: 50px;

					p {
						font-size: 14px;

						span {
							font-size: 20px;
						}
					}
				}
			}

			// &:last-child {
			// 	border-bottom: 0;
			// }
		}
	}

	.ant-skeleton.ant-skeleton-active {
		padding: 0 15px;
	}

	.notification_widget {
		.widget_row {
			flex-wrap: wrap;
			background-color: #e9e9e9;
			border-radius: 10px;

			.widget_top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;

				.title {
					margin: 0;
					line-height: normal;
					width: 70%;
					text-overflow: ellipsis;
					overflow: hidden;
				}

				.date {
					margin: 0;
					line-height: normal;
					width: 30%;
					text-align: right;
				}
			}

			.widget_bottom p {
				line-height: 18px;
				font-size: 12px;
				margin-top: 8px;
				margin-bottom: 0;
				color: #858181;
			}
		}
	}

	.anniversary_widget {
		.widget_row {
			justify-content: space-between;
			border-bottom: 1px solid $black_200;

			.widget_date {
				margin: 0;
				border: 0;
				padding: 7px;
				background-color: $black_200;
				border-radius: 8px 8px 0 8px;
			}
		}

		@include desktop {
			.widget_row {
				.widget_name {
					p {
						span {
							font-size: 10px;
						}
					}
				}

				.widget_date {
					padding: 5px;
				}
			}
		}
	}

	.zform_widget {
		.widget_row {
			background-color: #ddd;
			border-radius: 50px;
			justify-content: space-between;
			margin-bottom: 15px;
			padding: 0;
			height: 52px;

			.widget_left {
				padding-left: 30px;

				p {
					margin: 0;
					font-size: 16px;
					color: #7c7c7c;
					cursor: pointer;
				}
			}

			.widget_right {
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				min-width: 82px;
				border-radius: 50px;
				font-size: 26px;
				color: $white_color;

				p {
					margin: 0;
				}
			}

			&.orange_row {
				background-color: #fff1e4;

				.widget_right {
					background: -moz-linear-gradient(-58deg, #ff5843 0%, #ffbd37 100%);
					background: -webkit-linear-gradient(-58deg, #ff5843 0%, #ffbd37 100%);
					background: -ms-linear-gradient(-58deg, #ff5843 0%, #ffbd37 100%);
					background: linear-gradient(-58deg, #ff5843 0%, #ffbd37 100%);
				}
			}

			&.blue_row {
				background-color: #ebf5ff;

				.widget_right {
					background: -moz-linear-gradient(-58deg, rgb(142, 96, 246) 0%, rgb(85, 193, 232) 100%);
					background: -webkit-linear-gradient(-58deg, rgb(142, 96, 246) 0%, rgb(85, 193, 232) 100%);
					background: -ms-linear-gradient(-58deg, rgb(142, 96, 246) 0%, rgb(85, 193, 232) 100%);
					background: linear-gradient(-58deg, rgb(142, 96, 246) 0%, rgb(85, 193, 232) 100%);
				}
			}

			&.red_row {
				background-color: #ffeef1;

				.widget_right {
					background: -moz-linear-gradient(-58deg, rgb(255, 66, 102) 0%, rgb(252, 75, 41) 100%);
					background: -webkit-linear-gradient(-58deg, rgb(255, 66, 102) 0%, rgb(252, 75, 41) 100%);
					background: -ms-linear-gradient(-58deg, rgb(255, 66, 102) 0%, rgb(252, 75, 41) 100%);
					background: linear-gradient(-58deg, rgb(255, 66, 102) 0%, rgb(252, 75, 41) 100%);
				}
			}

			&.purple_row {
				background-color: #f4ecfe;

				.widget_right {
					background: -moz-linear-gradient(-58deg, rgb(129, 103, 251) 0%, rgb(188, 143, 209) 100%);
					background: -webkit-linear-gradient(-58deg, rgb(129, 103, 251) 0%, rgb(188, 143, 209) 100%);
					background: -ms-linear-gradient(-58deg, rgb(129, 103, 251) 0%, rgb(188, 143, 209) 100%);
					background: linear-gradient(-58deg, rgb(129, 103, 251) 0%, rgb(188, 143, 209) 100%);
				}
			}

			&.green_row {
				background-color: #e4f9ef;

				.widget_right {
					background: -moz-linear-gradient(-58deg, rgb(84, 208, 113) 0%, rgb(136, 234, 217) 99%);
					background: -webkit-linear-gradient(-58deg, rgb(84, 208, 113) 0%, rgb(136, 234, 217) 99%);
					background: -ms-linear-gradient(-58deg, rgb(84, 208, 113) 0%, rgb(136, 234, 217) 99%);
					background: linear-gradient(-58deg, rgb(84, 208, 113) 0%, rgb(136, 234, 217) 99%);
				}
			}

			&.pink_row {
				background-color: #ffeeff;

				.widget_right {
					background: -moz-linear-gradient(-58deg, rgb(236, 91, 214) 0%, rgb(228, 185, 244) 100%);
					background: -webkit-linear-gradient(-58deg, rgb(236, 91, 214) 0%, rgb(228, 185, 244) 100%);
					background: -ms-linear-gradient(-58deg, rgb(236, 91, 214) 0%, rgb(228, 185, 244) 100%);
					background: linear-gradient(-58deg, rgb(236, 91, 214) 0%, rgb(228, 185, 244) 100%);
				}
			}
		}

		@include desktop {
			.widget_row {
				height: 44px;

				.widget_left {
					p {
						font-size: 14px;
					}
				}

				.widget_right {
					min-width: 65px;

					p {
						font-size: 22px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				margin-bottom: 10px;

				.widget_left {
					padding-left: 20px;

					p {
						line-height: normal;
					}
				}
			}
		}
	}

	.inquiryRating_widget {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0 15px;

		.widget_row {
			width: 46%;
			border: 0;
			margin-bottom: 20px;
			box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.75);
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			height: 100px;

			.widget_name {
				p {
					text-transform: capitalize;
					cursor: pointer;
				}
			}

			.widget_date {
				margin: 0;
				padding: 0;
				min-width: 1px;
				border-right: 0;

				p {
					font-size: 24px;
				}
			}

			&.hot_rating {
				background-image: url(../../images/hot-rating.png);

				.widget_date {
					p {
						color: #f91717;
					}
				}
			}

			&.warm_rating {
				background-image: url(../../images/Warm-rating.png);

				.widget_date {
					p {
						color: #fa8c00;
					}
				}
			}

			&.cool_rating {
				background-image: url(../../images/Cool-rating.png);

				.widget_date {
					p {
						color: #21bdfa;
					}
				}
			}

			&.all_rating {
				background-image: url(../../images/All-rating.png);

				.widget_date {
					p {
						color: #8afff7;
					}
				}
			}
		}

		@include desktop {
			.widget_row {
				height: 80px;
				background-size: cover;

				.widget_name {
					font-size: 14px;
				}

				.widget_date {
					p {
						font-size: 22px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				height: 100px;
				width: 100%;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: bottom;
			}
		}
	}

	.zform_widget_detail {
		padding: 0 15px;

		.widget_row {
			height: 65px;
			padding: 0;
			margin-bottom: 15px;
			box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.14);
			border-radius: 10px;
			overflow: hidden;

			.widget_left {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 20%;
				padding: 10px;
				border-radius: 10px;

				img {
					max-width: 30px;
				}
			}

			.widget_right {
				height: 100%;
				display: flex;
				width: 80%;
				padding: 10px 20px;
				flex-direction: column;
				justify-content: center;

				p {
					font-size: 16px;
					color: $input_label_color;
					margin-bottom: 0;
					line-height: normal;
					cursor: pointer;
				}

				span {
					font-size: 26px;
					line-height: normal;
					margin-top: 4px;
				}
			}
		}

		@include desktop {
			.widget_row {
				height: 60px;

				.widget_left {
					img {
						max-width: 25px;
					}
				}

				.widget_right {
					padding: 5px 12px;

					p {
						font-size: 14px;
						line-height: 14px;
					}

					span {
						font-size: 22px;
						line-height: 22px;
					}
				}
			}
		}
	}

	.post_zform_widget {
		padding: 0 15px;

		.widget_row_color {
			margin-bottom: 30px;
			box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.14);
			border-radius: 10px;
			overflow: hidden;
			display: flex;
			justify-content: flex-end;

			.widget_row {
				background-color: $white_color;
				width: calc(100% - 10px);
				border-radius: 10px 0 0 10px;
				justify-content: space-between;

				.widget_right {
					p {
						font-size: 16px;
						color: #7c7c7c;
						margin-bottom: 0;
					}

					span {
						font-size: 28px;
						line-height: normal;
						margin-top: 4px;
					}
				}

				.widget_left {
					img {
						max-width: 60px;
					}
				}
			}
		}
	}

	.ZformApprovalLine {
		margin-bottom: 30px;

		h3 {
			font-size: 16px;
			color: #7c7c7c;
			margin-bottom: 0;
		}

		.ant-progress-show-info {
			display: flex;
			justify-content: space-between;

			.ant-progress-outer {
				width: calc(100% - 60px);
			}

			.ant-progress-text {
				font-size: 28px;
				line-height: normal;
				width: 60px;
			}
		}

		&.greenProgress {
			.ant-progress-bg {
				background-color: #80c564;
				opacity: 0.8;
			}

			.ant-progress-text {
				color: #80c564;
			}
		}

		&.orangeProgress {
			.ant-progress-bg {
				background-color: #ed7b0c;
				opacity: 0.8;
			}

			.ant-progress-text {
				color: #ed7b0c;
			}
		}

		&.blueProgress {
			.ant-progress-bg {
				background-color: #269ae9;
				opacity: 0.8;
			}

			.ant-progress-text {
				color: #269ae9;
			}
		}

		&.purpleProgress {
			.ant-progress-bg {
				background-color: #742fff;
				opacity: 0.8;
			}

			.ant-progress-text {
				color: #742fff;
			}
		}
	}

	.inquiry_status_widget {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0 15px;

		.widget_row {
			width: 48%;
			justify-content: space-between;
			border-radius: 50px;
			margin-bottom: 15px;
			padding: 10px 18px;

			.widget_left p {
				margin: 0;
				font-size: 16px;
				line-height: normal;
				color: $white_color;
			}

			.widget_right p {
				margin: 0;
				line-height: normal;
				color: $white_color;
				font-size: 26px;
			}
		}

		@include desktop {
			.widget_row {
				width: 100%;
				padding: 8px 15px;

				.widget_left {
					p {
						font-size: 14px;
					}
				}

				.widget_right {
					p {
						font-size: 22px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				padding: 6px 15px;
				margin-bottom: 10px;
			}
		}
	}

	.fund_tran_widget {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.widget_row {
			justify-content: space-between;
			border-radius: 50px;
			margin-bottom: 15px;
			padding: 5px 15px 5px 5px;
			width: 48%;

			.widget_left {
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.fund_icon {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					margin-right: 8px;
					background-repeat: no-repeat;
					background-size: 25px;
					background-position: center;
				}

				p {
					margin: 0;
					line-height: normal;
					width: calc(100% - 50px);
				}
			}

			.widget_right {
				p {
					margin: 0;
					line-height: normal;
					font-size: 26px;
				}
			}
		}

		.green_row {
			background-color: rgba(150, 194, 131, 0.2);
			border: 1px solid #96c283;

			.fund_icon {
				background-color: #96c283;
				background-image: url(../../images/icons/widgetIcon/fund_Received.png);
			}
		}

		.blue_row {
			background-color: rgba(98, 166, 235, 0.2);
			border: 1px solid #62a6eb;

			.fund_icon {
				background-color: #62a6eb;
				background-image: url(../../images/icons/widgetIcon/fund_Deposited.png);
			}
		}

		.orange_row {
			background-color: rgba(255, 181, 52, 0.2);
			border: 1px solid #ffb534;

			.fund_icon {
				background-color: #ffb534;
				background-image: url(../../images/icons/widgetIcon/fund_Refunds.png);
			}
		}

		.red_row {
			background-color: rgba(249, 9, 10, 0.2);
			border: 1px solid #f9090a;

			.fund_icon {
				background-color: #f9090a;
				background-image: url(../../images/icons/widgetIcon/fund_Open_Cancellations.png);
			}
		}

		@include desktop {
			.widget_row {
				width: 100%;
				padding: 2px 15px 2px 2px;

				.widget_left {
					.fund_icon {
						width: 32px;
						height: 32px;
						background-size: 20px;
					}

					p {
						width: auto;
						font-size: 12px;
					}
				}

				.widget_right {
					p {
						font-size: 22px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				margin-bottom: 10px;
			}
		}
	}

	.active_insurance {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.widget_row {
			position: relative;
			width: 50%;
			border: 0;
			margin-bottom: 15px;

			.widget_left {
				position: absolute;
				top: -4px;
				left: 50%;
				transform: translateX(-50%);
				background-color: #7cb363;
				color: #fff;
				padding: 6px 2px;
				min-width: 158px;
				text-align: center;
				border-radius: 50px;

				p {
					margin: 0;
					line-height: normal;
				}
			}

			.widget_right {
				width: 100%;
				border: 1px solid #ddd;
				border-radius: 10px;
				text-align: center;
				padding: 40px 10px 15px;

				p {
					margin: 0;
					line-height: normal;
					font-size: 26px;
				}
			}
		}

		@include desktop {
			.widget_row {
				padding: 10px 5px;

				.widget_left {
					min-width: 110px;

					p {
						font-size: 12px;
					}
				}

				.widget_right {
					padding: 25px 10px 6px;

					p {
						font-size: 22px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				width: 100%;
				margin-bottom: 8px;

				.widget_left {
					min-width: 130px;
				}
			}
		}
	}

	.insurance_quotation {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.widget_row {
			width: 48%;
			border: 0;
			// background-color: #ddd;
			margin-bottom: 15px;
			flex-wrap: wrap;
			flex-direction: column;
			height: 96px;
			justify-content: space-between;
			border-radius: 8px;
			color: $white_color;

			.widget_right {
				align-self: flex-start;

				p {
					line-height: normal;
					font-size: 26px;
					margin-bottom: 0;
				}
			}

			.widget_left {
				align-self: flex-end;
				width: 100%;
				text-align: right;

				p {
					font-size: 16px;
					line-height: normal;
					margin-bottom: 0;
				}
			}

			&.pinkGradient {
				background: -moz-linear-gradient(-58deg, #11998e 0%, #38ef7d 100%);
				background: -webkit-linear-gradient(-58deg, #11998e 0%, #38ef7d 100%);
				background: -ms-linear-gradient(-58deg, #11998e 0%, #38ef7d 100%);
				background: linear-gradient(-58deg, #11998e 0%, #38ef7d 100%);
			}

			&.purpleGradient {
				background: -moz-linear-gradient(-58deg, rgb(178, 163, 229) 0%, rgb(255, 106, 128) 99%);
				background: -webkit-linear-gradient(-58deg, rgb(178, 163, 229) 0%, rgb(255, 106, 128) 99%);
				background: -ms-linear-gradient(-58deg, rgb(178, 163, 229) 0%, rgb(255, 106, 128) 99%);
				background: linear-gradient(-58deg, rgb(178, 163, 229) 0%, rgb(255, 106, 128) 99%);
			}

			&.blueGradient {
				background: -moz-linear-gradient(-58deg, rgb(108, 234, 242) 0%, rgb(102, 166, 255) 99%);
				background: -webkit-linear-gradient(-58deg, rgb(108, 234, 242) 0%, rgb(102, 166, 255) 99%);
				background: -ms-linear-gradient(-58deg, rgb(108, 234, 242) 0%, rgb(102, 166, 255) 99%);
				background: linear-gradient(-58deg, rgb(108, 234, 242) 0%, rgb(102, 166, 255) 99%);
			}

			&.orangeGradient {
				background: -moz-linear-gradient(-58deg, rgb(255, 222, 101) 0%, rgb(255, 69, 69) 100%);
				background: -webkit-linear-gradient(-58deg, rgb(255, 222, 101) 0%, rgb(255, 69, 69) 100%);
				background: -ms-linear-gradient(-58deg, rgb(255, 222, 101) 0%, rgb(255, 69, 69) 100%);
				background: linear-gradient(-58deg, rgb(255, 222, 101) 0%, rgb(255, 69, 69) 100%);
			}
		}

		@include desktop {
			.widget_row {
				padding: 10px 8px;
				height: 86px;

				.widget_right {
					p {
						font-size: 22px;
					}
				}

				.widget_left {
					p {
						font-size: 14px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				width: 100%;
				height: 76px;
				margin-bottom: 12px;
			}
		}
	}

	.active_insurance {
		.green_text {
			.widget_left {
				background-color: #7cb363;
			}
		}

		.blue_text {
			.widget_left {
				background-color: #68a1d7;
			}
		}

		.orange_text {
			.widget_left {
				background-color: #ef7c3d;
			}
		}

		.yellow_text {
			.widget_left {
				background-color: #ffb534;
			}
		}

		.red_text {
			.widget_left {
				background-color: #f9090a;
			}
		}
	}

	.insurance_fund_widget {
		display: flex;
		justify-content: space-between;

		.widget_row {
			flex-direction: column;
			width: 50%;

			.widget_top {
				width: 140px;
				height: 140px;
				border: 8px solid;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;

				p {
					margin: 0;
					line-height: normal;
					font-size: 26px;
				}
			}

			&.green_row .widget_top {
				background-color: rgba(124, 179, 99, 0.2);
				border-color: #7cb363;
			}

			&.blue_row .widget_top {
				background-color: rgba(104, 161, 215, 0.2);
				border-color: #68a1d7;
			}
		}

		@include desktop {
			.widget_row {
				.widget_top {
					width: 100px;
					height: 100px;
					border: 6px solid;

					p {
						font-size: 22px;
					}
				}

				.widget_bottom {
					p {
						font-size: 12px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				.widget_top {
					width: 80px;
					height: 80px;
					border: 4px solid;
				}
			}
		}
	}

	.active_finance_widget {
		.widget_row {
			justify-content: space-between;
			border-left: 5px solid;
			margin-bottom: 15px;

			.widget_left {
				p {
					font-size: 16px;
					color: #7c7c7c;
					margin-bottom: 0;
					line-height: normal;
					cursor: pointer;
				}
			}

			.widget_right {
				p {
					margin: 0;
					font-size: 26px;
					line-height: normal;
				}
			}

			&.blueRow {
				border-color: #3b90f8;
				@include themeColorOpacity(#3b90f8, 0.2);

				.widget_right p {
					color: #3b90f8;
				}
			}

			&.greenRow {
				border-color: #80c564;
				@include themeColorOpacity(#80c564, 0.2);

				.widget_right p {
					color: #80c564;
				}
			}

			&.orangeRow {
				border-color: #ffb534;
				@include themeColorOpacity(#ffb534, 0.2);

				.widget_right p {
					color: #ffb534;
				}
			}
		}

		@include desktop {
			.widget_row {
				.widget_left p {
					font-size: 14px;
				}

				.widget_right p {
					font-size: 22px;
				}
			}
		}
	}
}

.widgetRow {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	height: calc(100vh - 150px);
	overflow-x: auto;
	margin-left: -10px;
	margin-right: -10px;

	.widgetCol {
		width: 33.33%;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.dashboard_widget_block .widget_wrapper>div,
.dashboard_widget_block .widget_wrapper>ul {
	padding-top: 10px;
}


.e-kanban {
	background: transparent;
}

.e-kanban .e-kanban-table .e-header-cells {
	display: none;
}

.e-kanban .e-kanban-content {
	padding: 0;
}

.e-kanban .e-kanban-table.e-content-table .e-content-row:not(.e-swimlane-row) td {
	background-color: transparent;
	border: 0;
	width: 25% !important;
}

.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container {
	padding: 0;
}

.e-kanban .e-kanban-content .e-content-row:not(.e-swimlane-row) .e-content-cells:focus {
	border: 0;
}

.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card {
	background-color: transparent;
	border-radius: 12px;
	border: 0;
}

.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-empty-card,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-empty-card {
	display: none;
}

.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card:hover,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card:hover,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card:focus,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card:focus {
	border: 0;
}

.e-card,
.e-card:hover {
	box-shadow: none;
	font-family: "montserratmedium", Arial, sans-serif;
}


@include ipad {
	.e-kanban .e-kanban-table {
		table-layout: auto;
	}

	.e-card-wrapper {
		height: auto !important;
	}
}


// Info Page css
.infoListWrap {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	.noRecord {
		text-align: center;
		margin-top: 40px;
		color: $input_label_color;
	}
}

.infoSection {
	padding: 0;
	margin: 0;
	display: flex;
	flex-wrap: wrap;

	li {
		width: 50%;
		padding: 0 15px;
		margin-top: 30px;
		list-style-type: none;

		.infoBlock {
			padding: 15px;
			// border: 1px solid #ddd;
			border-radius: 8px;
			box-shadow: 0px 0px 11px -7px rgba(0, 0, 0, 0.75);
			cursor: pointer;

			.infoHeader {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-bottom: 15px;

				.infoTitle {
					line-height: 22px;
					color: $primary_color;
					width: calc(100% - 110px);
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}

				.infoDate {
					font-size: 14px;
					color: $black_500;

					span {
						margin-right: 8px;
					}
				}
			}

			.infoNote {
				font-size: 14px;
				color: #666666;
				letter-spacing: 0.5px;
				height: 65px;
				overflow: hidden;
			}
		}

		&.read .infoBlock {
			background-color: #ffffff;
		}

		&.unRead {
			.infoBlock {
				background-color: #e8e8e8;
				border-left: 3px solid #a4a4a4;
			}
		}
	}
}

.customPagination {
	padding: 30px 0;
	text-align: center;

	ul {
		margin: 0;
		padding: 0 12px;
		display: inline-block;

		li {
			display: inline-block;
			width: 30px;
			height: 30px;
			padding: 8px 2px;
			margin: 0 2px;
			text-align: center;
			color: $input_label_color;
			line-height: normal;
			font-size: 14px;
			cursor: pointer;
			transition: all .5s ease;

			&.active,
			&:hover {
				color: $primary_color;
				border: 1px solid $primary_color;
				border-radius: 8px;
			}
		}
	}

	.btnPrevNext {
		// background-color: #fff;
		width: 40px;
		display: inline-block;
		text-align: center;
		font-size: 14px;
		height: 30px;
		line-height: 30px;
		color: $input_label_color;
		cursor: pointer;
		transition: all .5s ease;
	}
}

.infoModal {
	.infoModalDate {
		font-size: 16px;
		color: #adacac;
		margin-bottom: 15px;
		text-align: right;

		span {
			margin-right: 8px;
		}
	}

	.infoModalNote {
		font-size: 16px;
		color: #565656;
		letter-spacing: 0.5px;
		margin-bottom: 30px;
	}
}

.infoFilterSec {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 30px;

	.infoFilter {
		display: inline-block;
		margin: 0 10px;
	}
}
