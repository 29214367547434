/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 20, 2021 */

@font-face {
	font-family: "montserratmedium";
	src: url("montserrat-medium-webfont.eot");
	src: url("montserrat-medium-webfont.eot?#iefix") format("embedded-opentype"),
		url("montserrat-medium-webfont.woff2") format("woff2"),
		url("montserrat-medium-webfont.woff") format("woff"),
		url("montserrat-medium-webfont.svg#montserratmedium") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "montserratmedium_italic";
	src: url("montserrat-mediumitalic-webfont.eot");
	src: url("montserrat-mediumitalic-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("montserrat-mediumitalic-webfont.woff2") format("woff2"),
		url("montserrat-mediumitalic-webfont.woff") format("woff"),
		url("montserrat-mediumitalic-webfont.svg#montserratmedium_italic")
			format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "montserratregular";
	src: url("montserrat-regular-webfont.eot");
	src: url("montserrat-regular-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("montserrat-regular-webfont.woff2") format("woff2"),
		url("montserrat-regular-webfont.woff") format("woff"),
		url("montserrat-regular-webfont.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
